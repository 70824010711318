<template>
  <div v-if="show" class="footerBox">
    <div class="footer">
      <ul>
        <div v-for="(item, index) in boxs" :key="index">
          <li
            :class="index == boxIndex ? 'on' : ''"
            v-on:click="onClick(item, index)"
            :style="{ width: boxWidth }"
          >
            <a>
              <img
                :src="index == boxIndex ? item.defaultImg : item.selectedImg"
              />
              <p>{{ item.title }}</p>
            </a>
          </li>
        </div>
      </ul>
    </div>
  </div>
</template>

<script>
import { Dialog, Notify } from "vant";

export default {
  name: "FooterBox",
  data() {
    return {
      show: true,
      boxIndex: -1,
      boxWidth: "25%",
      boxs: [
        {
          title: "客户录入",
          link: "/",
          defaultImg: require("@/assets/picture/f01.png"),
          selectedImg: require("@/assets/picture/f1.png"),
        },
        {
          title: "看房计划",
          link: "/list",
          defaultImg: require("@/assets/picture/f05.png"),
          selectedImg: require("@/assets/picture/f5.png"),
        },
        {
          title: "客户确认",
          link: "/complete",
          defaultImg: require("@/assets/picture/f02.png"),
          selectedImg: require("@/assets/picture/f2.png"),
        },
        {
          title: "我的",
          link: "/my",
          defaultImg: require("@/assets/picture/f04.png"),
          selectedImg: require("@/assets/picture/f4.png"),
        },
      ],
    };
  },
  methods: {
    onClick(item, index) {
      this.$router.push({
        path: item.link,
      });
      this.boxIndex = index;
    },

    checkRoute(to) {
      let checkRoutes = [
        "/register",
        "/login",
        "/list/listdetail1",
        "/customer/listdetail",
      ];
      let path = to.path;
      let isLogin = this.$store.state.isLogin;
      let usertype = this.$store.state.usertype;
      // 已登录，未补充个人信息，跳转完善个人信息页面
      if (isLogin && usertype == "medium" && path != "/my/userinfo") {
        let name = this.$store.state.userinfo.name;
        let institution = this.$store.state.userinfo.institution;
        console.log(
          "已登录，未补充个人信息，跳转完善个人信息页面",
          path,
          this.$store.state.userinfo.name,
          name.length,
          institution.length
        );
        if (name.length == 0 || institution.length == 0) {
          console.log("未补充个人信息,跳转信息编辑页面");
          Notify({
            type: "danger",
            message: "请完善真实姓名和机构名称",
            background: "#ee0a24",
          });
          this.$router.push({
            path: "/my/userinfo",
          });
        }
      }
      // 已登录访问注册页面，返回首页
      if (isLogin && checkRoutes.findIndex((item) => item == path) != -1) {
        console.log("usertype", usertype);
        if (usertype == "medium") {
          this.$router.push({
            path: "/list",
          });
        } else {
          this.$router.push({
            path: "/park/list",
          });
        }
      }
      // 未登录访问业务页面，返回登录页
      if (!isLogin && checkRoutes.findIndex((item) => item == path) == -1) {
        Dialog.alert({
          message: "您还未登录，请您登录！",
        }).then(() => {
          this.$router.push({
            path: "/register",
          });
        });
      }
      console.log(
        isLogin,
        path,
        checkRoutes.findIndex((item) => item == path)
      );
    },
    init() {
      if (this.$store.state.usertype != "medium") {
        this.boxs = [
          {
            title: "客户录入",
            link: "/park/",
            defaultImg: require("@/assets/picture/f01.png"),
            selectedImg: require("@/assets/picture/f1.png"),
          },
          {
            title: "看房计划",
            link: "/park/list",
            defaultImg: require("@/assets/picture/f05.png"),
            selectedImg: require("@/assets/picture/f5.png"),
          },
          {
            title: "客户确认",
            link: "/park/complete",
            defaultImg: require("@/assets/picture/f02.png"),
            selectedImg: require("@/assets/picture/f2.png"),
          },
          {
            title: "我的",
            link: "/park/my",
            defaultImg: require("@/assets/picture/f04.png"),
            selectedImg: require("@/assets/picture/f4.png"),
          },
        ];
      } else {
        this.boxs = [
          {
            title: "客户录入",
            link: "/",
            defaultImg: require("@/assets/picture/f01.png"),
            selectedImg: require("@/assets/picture/f1.png"),
          },
          {
            title: "看房计划",
            link: "/list",
            defaultImg: require("@/assets/picture/f05.png"),
            selectedImg: require("@/assets/picture/f5.png"),
          },
          {
            title: "客户确认",
            link: "/complete",
            defaultImg: require("@/assets/picture/f02.png"),
            selectedImg: require("@/assets/picture/f2.png"),
          },
          {
            title: "我的",
            link: "/my",
            defaultImg: require("@/assets/picture/f04.png"),
            selectedImg: require("@/assets/picture/f4.png"),
          },
        ];
      }
      this.boxWidth = 100 / this.boxs.length + "%";
    },
  },
  created() {
    this.init();
  },
  watch: {
    $route(to) {
      this.init();
      let that = this;
      that.boxIndex = -1;
      that.boxs.forEach(function (item, index) {
        if (item.link == "/" && item.link == to.path) {
          that.boxIndex = index;
        }
        if (item.link != "/" && to.path.indexOf(item.link) == 0) {
          that.boxIndex = index;
        }
        console.log("topath", to.path, item.link);
        console.log(item.link != "/" && item.link.indexOf(to.path) != -1);
      });
      if (that.boxIndex == -1) {
        that.show = false;
      } else {
        that.show = true;
      }

      this.checkRoute(to);
    },
  },
};
</script>

<style scoped>
.footerBox {
  width: 100%;
  /*max-width: 512px;*/
  /*margin: 0 auto;*/
  padding: 0;
}

.footerBox .footer {
  position: fixed;
  width: 100%;
  /*max-width: 512px;*/
  bottom: 0;
  left: 0;
  /*height: 80px;*/
  z-index: 999;
  font-size: 9px;
}

.footerBox .footer ul {
  overflow: hidden;
  border-top: 1px solid #e7e7e7;
  box-sizing: border-box;
  background: #fff;
  padding: 0px;
  margin: 0px;
}

.footerBox .footer ul li {
  /*width: 25%;*/
  /*height: 80px;*/
  float: left;
  overflow: hidden;
}

.footerBox .footer ul li a {
  display: block;
  overflow: hidden;
}

.footerBox .footer ul li img {
  height: 30px;
  display: block;
  margin: 0 auto;
  margin-top: 3px;
}

.footerBox .footer ul li p {
  text-align: center;
  height: 24px;
  line-height: 24px;
  color: #969696;
  letter-spacing: 1px;
  margin: 0px 0px 0px 3px;
  font-size: 14px;
}

.footerBox .footer ul li.on p {
  color: #0e4acd;
}
</style>
