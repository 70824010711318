import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// //先把VueRouter.prototype身上的push|replace方法进行保存一份
// let originPush = VueRouter.prototype.push;
// let originReplace = VueRouter.prototype.replace;
// //重写VueRouter.prototype身上的push方法了
// VueRouter.prototype.push = function (location, resolve, reject) {
//   //第一个形参：路由跳转的配置对象（query|params）
//   //第二个参数：undefined|箭头函数（成功的回调）
//   //第三个参数:undefined|箭头函数（失败的回调）
//   if (resolve && reject) {
//     //push方法传递第二个参数|第三个参数（箭头函数）
//     //originPush：利用call修改上下文，变为(路由组件.$router)这个对象，第二参数：配置对象、第三、第四个参数：成功和失败回调函数
//     originPush.call(this, location, resolve, reject);
//   } else {
//     //push方法没有产地第二个参数|第三个参数
//     originPush.call(
//       this,
//       location,
//       () => {},
//       () => {}
//     );
//   }
// };
// //重写VueRouter.prototype身上的replace方法了
// VueRouter.prototype.replace = function (location, resolve, reject) {
//   if (resolve && reject) {
//     originReplace.call(this, location, resolve, reject);
//   } else {
//     originReplace.call(
//       this,
//       location,
//       () => {},
//       () => {}
//     );
//   }
// };

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const routes = [
  {
    path: "/",
    component: () => import("@/views/EnterView.vue"),
  },
  {
    path: "/list",
    component: () => import("@/views/ListView.vue"),
  },
  {
    path: "/list/listdetail",
    component: () => import("@/views/ListDetailView.vue"),
  },
  {
    path: "/complete",
    component: () => import("@/views/CompleteView.vue"),
  },
  {
    path: "/my",
    component: () => import("@/views/MyView.vue"),
  },
  {
    path: "/my/userinfo",
    component: () => import("@/views/UserInfoView.vue"),
  },
  {
    path: "/register",
    component: () => import("@/views/RegisterView.vue"),
  },
  {
    path: "/login",
    component: () => import("@/views/LoginView.vue"),
  },
  // park模块
  {
    path: "/park",
    component: () => import("@/views/park/EnterView.vue"),
  },
  {
    path: "/park/list",
    component: () => import("@/views/park/ListView.vue"),
  },
  {
    path: "/park/list/listdetail",
    component: () => import("@/views/park/ListDetailView.vue"),
  },
  {
    path: "/park/complete",
    component: () => import("@/views/park/CompleteView.vue"),
  },
  {
    path: "/park/my",
    component: () => import("@/views/park/MyView.vue"),
  },
  {
    path: "/park/my/userinfo",
    component: () => import("@/views/park/UserInfoView.vue"),
  },
  // 客户模块
  {
    path: "/customer/listdetail",
    component: () => import("@/views/customer/ListDetailView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
