import Vue from "vue";
import Vuex from "vuex";
import VuexAlong from "vuex-along";

Vue.use(Vuex);
Vue.use(VuexAlong);

export default new Vuex.Store({
  plugins: [
    VuexAlong({
      name: "store",
      local: true,
      session: {
        list: [],
        isFilter: true,
      },
    }),
  ],
  state: {
    token: "",
    userinfo: {
      headimgurl: "",
      name: "",
    },
    usernumber: {},
    isLogin: false,
    usertype: "medium",
    wechatInfo: {},
    isWechatLogin: false,
  },
  getters: {
    getToken(state) {
      return state.token;
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload.token;
      localStorage.setItem("token", payload.token);
      state.isLogin = true;
      state.usertype = payload.usertype;
    },
    logout(state) {
      state.token = "";
      state.isLogin = false;
      state.userinfo = null;
      localStorage.setItem("token", null);
    },
    setUserinfo(state, palyoad) {
      state.userinfo = palyoad;
    },
    setUsernumber(state, palyoad) {
      state.usernumber = palyoad;
    },
    setUserType(state, palyoad) {
      state.usertype = palyoad.usertype;
    },
    setWechatInfo(state, payload) {
      state.wechatInfo = payload.wechatInfo;
      state.isWechatLogin = true;
    },
    wechatLogout(state) {
      state.wechatInfo = {};
      state.isWechatLogin = false;
    },
  },
  actions: {},
  modules: {},
});
