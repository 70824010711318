<template>
  <div id="app">
    <router-view />

    <footerBox></footerBox>
  </div>
</template>

<script>
import footerBox from "@/components/FooterBox.vue";
import { getuserinfoApi } from "@/utils/http";
import { getuserinfoApi as getuserinfoParkApi } from "@/utils/parkHttp";
export default {
  name: "IndexView",
  components: {
    footerBox,
  },
  methods: {
    getUserinfo() {
      let that = this;
      if (that.$store.state.usertype == "medium") {
        getuserinfoApi().then((res) => {
          let info = res.data;
          that.$store.commit("setUserinfo", info.data.userInfo);
          that.$store.commit("setUsernumber", info.data.number);
          console.log(info);
        });
      } else {
        getuserinfoParkApi().then((res) => {
          let info = res.data;
          that.$store.commit("setUserinfo", info.data.userInfo);
          that.$store.commit("setUsernumber", info.data.number);
          console.log(info);
        });
      }
    },
    checkRoute() {
      let checkRoutes = ["/register", "/login", "/customer/listdetail1"];
      let path = this.$route.path;
      let isLogin = this.$store.state.isLogin;
      // 已登录访问注册页面，返回首页
      if (isLogin && checkRoutes.findIndex((item) => item == path) != -1) {
        this.$router.push({
          path: "/list",
        });
      }
      // 未登录访问业务页面，返回登录页
      if (!isLogin && checkRoutes.findIndex((item) => item == path) == -1) {
        this.$router.push({
          path: "/register",
        });
      }
      console.log(
        isLogin,
        path,
        checkRoutes.findIndex((item) => item == path)
      );
    },
  },
  created() {
    // this.checkRoute();
    // 已登录
    if (this.$store.state.isLogin) {
      this.getUserinfo();
    }

    console.log(1);
    console.log(this.$store.state.userinfo);
  },
  beforeUpdate() {
    console.log("beforMount");
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}
ul,
ol,
li,
dl,
dt,
dd {
  list-style: none;
}
#app {
  //font-family: Avenir, Helvetica, Arial, sans-serif;
  //-webkit-font-smoothing: antialiased;
  //-moz-osx-font-smoothing: grayscale;
  //text-align: center;
  //color: #2c3e50;
  width: 100%;
  max-width: 512px;
  margin: 0 auto;
  background: #fff;
  font-size: 12px;
  line-height: 24px;
  color: #333;
  padding: 0px;
}
body {
  margin: 0px;
}

a {
  text-decoration: none;
  outline: 0px;
  color: #333;
}

//nav {
//  padding: 30px;
//
//  a {
//    font-weight: bold;
//    color: #2c3e50;
//
//    &.router-link-exact-active {
//      color: #42b983;
//    }
//  }
//}
</style>
