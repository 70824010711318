import axios from "axios";
import { Dialog } from "vant";
import store from "@/store";
import router from "@/router";
// http://dongye.jingqiweb.com
//测试版
// const baseURL = "http://192.168.2.105:8000/index.php";
//正式版
const baseURL = "/";
// const baseURL = "http://insigma.jingqiweb.com/";
//创建一个axios实例
const request = axios.create({
  baseURL: baseURL,
  timeout: 1000 * 30,
  headers: {
    "Content-Type": "multipart/form-data",
    server: "true",
    "ba-user-token": "",
  },
});

// 请求拦截器，就是请求前做一些检查
request.interceptors.request.use(
  function (config) {
    let token = localStorage.getItem("token");
    if (token != undefined) {
      config.headers["ba-user-token"] = token;
    }
    config.headers["server"] = true;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
// 响应拦截器，对响应结果做一些处理
request.interceptors.response.use(
  function (response) {
    console.log("响应拦截器：响应结果", response);
    if (response.data.code == 302) {
      Dialog.alert({
        message: "请先登录！",
      }).then(() => {
        store.commit("logout");
        if (store.state.usertype == "medium") {
          router.push({
            path: "/register",
          });
        } else {
          router.push({
            path: "/login",
          });
        }
      });
    }
    if (response.data.code == 409) {
      Dialog.alert({
        message: "登录状态过期，请重新登录！",
      }).then(() => {
        store.commit("logout");
        if (store.state.usertype == "medium") {
          router.push({
            path: "/register",
          });
        } else {
          router.push({
            path: "/login",
          });
        }
      });
    }
    return response;
  },
  function (error) {
    if (error.response.status != 200) {
      // return Promise.reject(
      //   new Error(
      //     error.response ? error.response.data : { code: -1, msg: "玩过错误" }
      //   )
      // );
    }
    return Promise.reject(error);
  }
);
export default request;
