import request from "./api.js";

// 登录、注册
export function checkIn(params) {
  return request({
    url: "/api/park.user/checkIn",
    data: params,
    method: "post",
  });
}

// 退出登录
export function logout() {
  return request({
    url: "/api/park.user/logout",
    method: "post",
  });
}

// 获取用户信息
export function getuserinfoApi() {
  return request({
    url: "/api/park.user/index",
    method: "get",
  });
}

// 获取用户信息
export function editApi(params) {
  return request({
    url: "/api/park.user/edit",
    params: params,
    method: "get",
  });
}

// 保存用户信息
export function editSaveApi(data) {
  return request({
    url: "/api/park.user/edit",
    data: data,
    method: "post",
  });
}

// 获取中介列表信息
export function getParksApi() {
  return request({
    url: "/api/park.user/medium",
    method: "get",
  });
}

// 录入客户信息
export function createShowingApi(params) {
  return request({
    url: "/api/park.showings/create",
    data: params,
    method: "post",
  });
}

// 客户列表
export function listShowingApi(params) {
  return request({
    url: "/api/park.showings/index",
    data: params,
    method: "post",
  });
}

// 客户详情
export function detailShowingApi(params) {
  return request({
    url: "/api/park.showings/detail",
    params: params,
    method: "post",
  });
}

// 获取编辑客户详情
export function getEditShowingApi(params) {
  return request({
    url: "/api/park.showings/edit",
    params: params,
    method: "get",
  });
}

// 编辑客户信息
export function editShowingApi(params) {
  return request({
    url: "/api/park.showings/edit",
    data: params,
    method: "post",
  });
}

// 确认客户看房
export function confirmShowingApi(params) {
  return request({
    url: "/api/park.showings/confirm",
    data: params,
    method: "post",
  });
}
