// import request from '@/utils/request'

// export function getToken() {
//   return request({
//     url: '/qiniu/upload/token', // 假地址 自行替换
//     method: 'get'
//   })
// }\
import request from "./api.js";

// 登录、注册
export function checkIn(params) {
  return request({
    url: "/api/medium.user/checkIn",
    data: params,
    method: "post",
  });
}

// 退出登录
export function logout() {
  return request({
    url: "/api/medium.user/logout",
    method: "post",
  });
}

// 获取用户信息
export function getuserinfoApi() {
  return request({
    url: "/api/medium.user/index",
    method: "get",
  });
}

// 获取用户信息
export function editApi(params) {
  return request({
    url: "/api/medium.user/edit",
    params: params,
    method: "get",
  });
}

// 保存用户信息
export function editSaveApi(data) {
  return request({
    url: "/api/medium.user/edit",
    data: data,
    method: "post",
  });
}

// 获取微信用户信息
export function getWechatInfoApi(params) {
  return request({
    url: "/api/medium.user/wechatInfo",
    params: params,
    method: "get",
  });
}

// 获取微信用户信息
export function sendCodeApi(params) {
  return request({
    url: "/api/medium.user/sendCode",
    params: params,
    method: "get",
  });
}

// 获取园区信息
export function getParksApi() {
  return request({
    url: "/api/medium.user/parks",
    method: "get",
  });
}

// 获取区域信息
export function getAreasApi() {
  return request({
    url: "/api/medium.user/areas",
    method: "get",
  });
}

// 录入客户信息
export function createShowingApi(params) {
  return request({
    url: "/api/medium.showings/create",
    data: params,
    method: "post",
  });
}

// 客户列表
export function listShowingApi(params) {
  return request({
    url: "/api/medium.showings/index",
    data: params,
    method: "post",
  });
}

// 客户详情
export function detailShowingApi(params) {
  return request({
    url: "/api/medium.showings/detail",
    params: params,
    method: "post",
  });
}

// 获取编辑客户详情
export function getEditShowingApi(params) {
  return request({
    url: "/api/medium.showings/edit",
    params: params,
    method: "get",
  });
}

// 编辑客户信息
export function editShowingApi(params) {
  return request({
    url: "/api/medium.showings/edit",
    data: params,
    method: "post",
  });
}

// 确认客户看房
export function confirmShowingApi(params) {
  return request({
    url: "/api/medium.showings/confirm",
    data: params,
    method: "post",
  });
}
