import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {
  DatetimePicker,
  ActionSheet,
  Field,
  CellGroup,
  button,
  Form,
  List,
  popup,
  picker,
} from "vant";
import "vant/es/toast/style";

Vue.use(ActionSheet);
Vue.use(DatetimePicker);
Vue.use(Field);
Vue.use(CellGroup);
Vue.use(button);
Vue.use(Form);
Vue.use(List);
Vue.use(popup);
Vue.use(picker);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
